<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <div class="small-height"></div>
      <article
        class="white-text"
        style="background: rgba(0, 0, 0, 0.3); text-align: center"
      >
        <div style="font-size: 200%">TeahouseLab</div>
        <br /><br />
        #A Lab Focus On Linux Tech#<br /><br />
        <a href="https://github.com/TeaHouseLab" class="button">Github</a>
      </article>
    </div>
    <div class="col s1"></div>
  </div>
</template>
<style>
.container {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  padding: 50rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  width: 50%;
}
</style>