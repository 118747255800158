<template>
  <div
    class="modal top large"
    id="product"
    style="background: rgba(0, 0, 0, 0.3); height: 100vh; width: 100%"
    :class="{ active: active }"
    @click.self="close"
  >
    <article class="bottom-round">
      <a @click="close" class="active"
        ><i>arrow_backward</i>
        <h5 class="small-margin">Back</h5></a
      >
    </article>
    <br />
    <article v-for="product in products" :key="product" class="round">
      <h5>{{ product.title }}</h5>
      <div>{{ product.desc }}</div>
      <br />
      <router-link
        :to="product.link"
        class="button extend square round extra"
        @click="close"
      >
        <i>add</i>
        <span>View</span>
      </router-link>
    </article>
  </div>
</template>
<script>
export default {
  props: ["active"],
  data() {
    return {
      products: [
        {
          title: "CenterLinux",
          desc: "CenterLinux is an injectable Meta/Linux distro, written in fish shell",
          link: "/CenterLinux",
        },
        {
          title: 'ConvallariaLinux',
          desc: 'ConvallariaLinux is a debian-based linux distro for containerized system deliver',
          link: '/ConvallariaLinux'
        },
        {
          title: "CtPKG",
          desc: "CtPKG is the package manager for all kind of TeaHouseLab project",
          link: "/CtPKG",
        },
        {
          title: "CtContainer",
          desc: "Ctcontainer is a chroot/nspawn container manager system, support fetching rootfs from lxc image online repo",
          link: "/CtContainer",
        },
        {
          title: "DarkWater",
          desc: "DarkWater is an interactive api/http server, written in fish shell",
          link: "/DarkWater",
        },
        {
          title: "Progynosh",
          desc: "Progynosh is a script dev manager for fish script shell, helps you build your own fish shell script qiuckly",
          link: "/TsingkwaiProgynosh",
        }
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>