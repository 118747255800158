<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>CtContainer</h5>
        <a
          href="https://github.com/TeaHouseLab/ctcontainer"
          class="button round green"
          ><i>link</i><span>Available on Github</span></a
        >
        <button class="round green">Hairpin@build6</button>
        <br /><br />
        <details class="card round">
          <summary>Features:</summary>
          <p>Easy to understand and manage</p>
          <p>Quick and lightweight, only sized 32K</p>
          <p>Automatically download and setup container for you</p>
          <p>Compatible with lxc online image repo</p>
        </details>
        <details class="card round">
          <summary>Installation:</summary>
          <br />
          Install ctpkg first(Only ctpkg):<br /><br />
          <router-link class="button round green" to="/CtPKG"
            >CtPKG</router-link
          >
          <pre class="language-bash">
ctpkg upg -y
ctpkg grab ctcontainer -y
          </pre>
        </details>
        <details class="card round">
          <summary>Trouble Shooting:</summary>
          <br />
          <details class="card round">
            <summary>Nspawn nat network not working</summary>
            <br />
            <details class="card round">
              <summary>Ufw is enabled but route is not configured</summary>
              <br />
              <a
                class="button round"
                href="https://unix.stackexchange.com/a/710063/510597"
                >Solution on stackexchange</a
              >
            </details>
            <details class="card round">
              <br />
              Note: once you arrange the port to a machine, this port will nolonger works for other machine or the host machine
              <br />
              <summary>systemd-networkd is not managing the network</summary>
              Use systemd-networkd or NetworkManager to manage the network, put a # in front of the main interface in /etc/network/interface(for debian user)
            </details>
            <br />
            <details class="card round">
              <summary>DHCP server and ufw</summary>
              <br />
              Allow DHCP server port to communicate with machine
              <pre class="language-bash">ufw allow in 67/udp</pre>
            </details>
            <br />
            <details class="card round">
              <summary>You haven't allow system to route network traffic</summary>
              <br />
              <pre class="language-bash">sudo sysctl net.ipv4.ip_forward=1</pre>
            </details>
          </details>
          <br />
          <details class="card round">
            <summary>For archlinux/manjarolinux</summary>
            <br />
            <details class="card round">
              <summary>Key Problem related</summary>
              <pre class="language-bash">
[root@mydevice /]# pacman-key --init
[root@mydevice /]# sed -i 's/Required[[:space:]]DatabaseOptional/TrustAll/g' /etc/pacman.conf
[root@mydevice /]# pacman -Sy archlinux-keyring(if manjaro then use manjaro-keyring) nano
Then use nano to change the SigLevel from "TrustAll" to "Required DatabaseOptional" in /etc/pacman.conf</pre
              >
            </details>
            <details class="card round">
              <summary>Disk Space related</summary>
              <pre class="language-bash">
In archlinux container
[root@mydevice /]# sed -i 's/CheckSpace/#CheckSpace/g' /etc/pacman.conf    
                 </pre
              >
            </details>
          </details>
          <details class="card round">
            <summary>For busybox based distro</summary>
            <br />
            <details class="card round">
              <summary>Can't run bash</summary>
              <pre class="language-bash">
(ash/sh/zsh/bash)# source /etc/profile
--Or you can use this--
On login
~# ctcontainer run alpinelinux /bin/ash --login
                 </pre
              >
            </details>
          </details>
          <details class="card round">
            <summary>For Ubuntu based distro</summary>
            <br />
            <details class="card round">
              <summary>apt search in ubuntu found nothing</summary>
              <pre class="language-bash">
echo "deb http://archive.ubuntu.com/ubuntu impish main restricted universe multiverse" > /etc/apt/sources.list </pre
              >
            </details>
          </details>
        </details>
      </article>
    </div>
    <div class="col s1"></div>
  </div>
  <br /><br />
</template>
<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>