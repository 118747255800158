<template>
  <nav class="top-round menu bottom">
    <router-link v-for="product in products" :key="product" :to="product.link">
      <i>{{ product.icon }}</i>
      <span>{{ product.name }}</span>
    </router-link>
    <a @click="ToggleModal">
      <i> inventory </i>
      Products
    </a>
    <div class="modal top-round round medium bottom no-scroll"></div>
    <div class="modal top-round bottom"></div>
  </nav>
  <NavProduct :active="active" @close="ToggleModal" />
</template>
<script>
import NavProduct from "./SideNav/NavProduct.vue";

export default {
  components: {
    NavProduct,
  },
  data() {
    return {
      active: false,
      products: [
        {
          link: '/',
          icon: 'home',
          name: 'HomePage'
        },
        {
          link: '/About',
          icon: 'info',
          name: 'About'
        },
        {
          link: '/ReleaseTable',
          icon: 'new_releases',
          name: 'Release'
        }
      ]
    }
  },
  methods: {
    ToggleModal() {
      this.active = !this.active
    }
  }
};
</script>
<style scoped>
</style>