<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>DarkWater</h5>
        <a
          href="https://github.com/TeaHouseLab/darkwater"
          class="button round green"
          ><i>link</i><span>Available on Github</span></a
        >
        <button class="round orange">Quicksand@build7</button>
        <br /><br />
        <details class="card round">
          <summary>Installation:</summary>
          <br />
          Install ctpkg first(Only ctpkg):<br /><br />
          <router-link class="button round green" to="/CtPKG"
            >CtPKG</router-link
          >
          <pre class="language-bash">
ctpkg grab darkwater
sudo mkdir -p /etc/centerlinux/conf.d/
darkwater c
sudo mv darkwater /etc/centerlinux/conf.d/
       </pre>
        </details>
        <details class="card round">
          <summary>Configure:</summary>
          Default configure file location:
          /etc/centerlinux/conf.d/darkwater.conf<br />
          Protip: Use darkwater without option to see help document
          <br />
          <pre class="language-bash">
ip=0.0.0.0 (ip to bind)
port=8000 (port to listen)
index=index.fish (when visitor access the site without a path or just a /, redirct to here)
webroot=/var/www/darkwater (webroot, you know what is this)
logcat=debug (info or debug, sorry, no discard)
cert=/etc/centerlinux/conf.d/server.crt
key=/etc/centerlinux/conf.d/server.key
       </pre>
        </details>
        <br />
      </article>
    </div>
    <div class="col s1"></div>
  </div><br /><br />
</template>
<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>