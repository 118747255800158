<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>CenterLinux</h5>
        <a
          href="https://github.com/TeaHouseLab/CenterLinux"
          class="button round green"
          ><i>link</i><span>Available on Github</span></a
        >
        <button class="round green">Hairpin@build6</button>
        <br /><br />
        <details class="card round">
          <summary>Features:</summary>
          <p>
            An independent package manager called ctpkg<br />
            Code in fish shell script<br />
            HomeMade gnome/kde theme
          </p>
        </details>
        <details class="card round">
          <summary>Installation:</summary>
          Install coreutils, file, tar(by GNU) and curl, sudo
          <p>
            You don't need to install CenterOS for using ctpkg, click on ctpkg
            section to see how to install it manually
          </p>
          <pre class="language-bash">
$ curl -o ctos-injector && sudo fish ctos-injector injector</pre
          >
        </details>
        <br />
      </article>
    </div>
    <div class="col s1"></div>
  </div><br /><br />
</template>
<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>