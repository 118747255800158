<template>
  <SideNav />
  <router-view />
</template>
<script>
import SideNav from "./components/SideNav.vue";
export default {
  components: {
    SideNav,
  },
};
</script>
<style>
body {
  background-image: url(./assets/bg.webp);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
</style>
