import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import CenterLinux from '../views/CenterLinux.vue'
import AboutMe from '../views/AboutMe.vue'
import ReleaseTable from '../views/ReleaseTable.vue'
import DarkWater from '../views/DarkWater.vue'
import TsingkwaiProgynosh from '../views/TsingkwaiProgynosh.vue'
import CtPKG from '../views/CtPKG.vue'
import CtContainer from '../views/CtContainer.vue'
import ConvallariaLinux from '../views/ConvallariaLinux.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/CenterLinux',
    name: 'centerlinux',
    component: CenterLinux
  },
  {
    path: '/DarkWater',
    name: 'darkwater',
    component: DarkWater
  },
  {
    path: '/About',
    name: 'about',
    component: AboutMe
  },
  {
    path: '/ReleaseTable',
    name: 'releasetable',
    component: ReleaseTable
  },
  {
    path: '/TsingkwaiProgynosh',
    name: 'tsingkwaiprogynosh',
    component: TsingkwaiProgynosh
  },
  {
    path: '/CtPKG',
    name: 'ctpkg',
    component: CtPKG
  },
  {
    path: '/CtContainer',
    name: 'ctcontainer',
    component: CtContainer
  },
  {
    path: '/ConvallariaLinux',
    name: 'convallarialinux',
    component: ConvallariaLinux
  },
  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
