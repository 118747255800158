<template>
  <article>
    <table>
      <thead>
        <th align="center">Product</th>
        <th align="center">Version</th>
      </thead>
      <tbody>
        <template v-for="item in ProjectRelease" :key="item">
          <tr>
            <th align="center">{{ item.title }}</th>
            <th align="center">
              <button
                class="round"
                :class="{
                  green: item.update == true,
                  orange: !item.update,
                  red: item.update == 'outdate',
                }"
              >
                {{ item.release }}
              </button>
            </th>
          </tr>
        </template>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th align="center">華語</th>
          <th align="center">English(codename)</th>
          <th align="center">時間(Time)</th>
          <th align="center">狀態(status)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">苦艾酒</td>
          <td align="center">Absinthe</td>
          <td align="center">x</td>
          <td align="center">即將到來(Coming soon)</td>
        </tr>
        <tr>
          <td align="center">髮夾</td>
          <td align="center">Hairpin</td>
          <td align="center">2022-6-20</td>
          <td align="center">支持中(Supporting)</td>
        </tr>
        <tr>
          <td align="center">流沙</td>
          <td align="center">Quicksand</td>
          <td align="center">2022-4-24</td>
          <td align="center">延長支持期(LTS)</td>
        </tr>
        <tr>
          <td align="center">快銀</td>
          <td align="center">QuickSliverR</td>
          <td align="center">2022-4-10</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">柯里昂</td>
          <td align="center">Corleone</td>
          <td align="center">2022-3-6</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">海棠</td>
          <td align="center">Begonia</td>
          <td align="center">2022-2-3</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">黑死病</td>
          <td align="center">BlackDeath</td>
          <td align="center">2022-1-24</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">金釵</td>
          <td align="center">GoldenHairpin</td>
          <td align="center">跳過(skipped)</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">蜜柚</td>
          <td align="center">pomelo</td>
          <td align="center">2022-1-8</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">霜花</td>
          <td align="center">FrostFlower</td>
          <td align="center">2021-12-3</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">雲女孩</td>
          <td align="center">CloudGirl</td>
          <td align="center">2021-11-26</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">卡西尼號</td>
          <td align="center">Cassini</td>
          <td align="center">2021-11-03</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">燭火</td>
          <td align="center">CandleLight</td>
          <td align="center">2021-10-23</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">塵灰</td>
          <td align="center">Ash</td>
          <td align="center">跳過(skipped)</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">昏沉</td>
          <td align="center">Darkness</td>
          <td align="center">2021-10-05</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">花火</td>
          <td align="center">Hanabi</td>
          <td align="center">2021-9-12</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">破曉</td>
          <td align="center">Dawn</td>
          <td align="center">2021-08-27</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">落英</td>
          <td align="center">Fallen_Leaves</td>
          <td align="center">2021-8-11</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">白牆黑瓦</td>
          <td align="center">Whitewashed wall and Grey tiles</td>
          <td align="center">2021-08-08</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">山月</td>
          <td align="center">MoonAlpine</td>
          <td align="center">2021-05-15</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">薄荷之夏</td>
          <td align="center">MintLove</td>
          <td align="center">2019-06-14</td>
          <td align="center">x</td>
        </tr>
        <tr>
          <td align="center">留青梳</td>
          <td align="center">GreenComb</td>
          <td align="center">2017-09-12</td>
          <td align="center">x</td>
        </tr>
      </tbody>
    </table>
    <br /><br />
  </article>
</template>
<script>
export default {
  data() {
    return {
      ProjectRelease: [
        {
          title: "CenterLinux",
          release: "Hairpin@build6",
          update: true,
        },
        {
          title: "CtPKG",
          release: "Hairpin@build6",
          update: true,
        },
        {
          title: "CtContainer",
          release: "Hairpin@build6",
          update: true,
        },
        {
          title: "DarkWater",
          release: "Quicksand@build7",
          update: false,
        },
        {
          title: "Progynosh",
          release: "Quicksand@build1",
          update: "outdate",
        },
      ],
      PastRelease: [
        {
          title: "苦艾酒",
          cm: "Absinthe",
          date: "x",
          stat: "即將到來(Coming soon)"
        }
      ]
    };
  },
};
</script>