<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>Progynosh</h5>
        <a
          href="https://github.com/happyeggchen/progynosh"
          class="button round green"
          ><i>link</i><span>Available on Github</span></a
        >
        <button class="round red">Quicksand@build1</button>
        <br /><br />
        <details class="card round">
          <summary>Features:</summary>
          <p>Written in fish shell, compose for fish shell script</p>
          <p>Fetch modules from online repo</p>
        </details>
        <details class="card round">
          <summary>Installation:</summary>
          <br />
          Install ctpkg first(Only ctpkg):<br /><br />
          <router-link class="button round green" to="/CtPKG"
            >CtPKG</router-link
          >
          <pre class="language-bash">
ctpkg upg -y
ctpkg grab progynosh -y
      </pre>
        </details>
      </article>
    </div>
    <div class="col s1"></div>
  </div>
  <br /><br />
</template>
<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>