<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>CtPKG</h5>
        <a
          href="https://github.com/TeaHouseLab/ctpkg"
          class="button round green"
          ><i>link</i>Available on Github</a
        >
        <button class="round green">Hairpin@build6</button>
        <br /><br />
        <details class="card round">
          <summary>Features:</summary>
          <p>
            A bridge/frontend/warp for different package managers , and a
            userland package manager included , written in progynosh(fish)
          </p>
          <p>Protip: Use ctpkg without options to see help document</p>
        </details>
        <details class="card round">
          <summary>Installation:</summary>
          <p>
            Make sure curl,file,tar,core-utils,fish-shell has been installed in
            your system (if you’re on openwrt, do this before installation: opkg
            install tar fish curl file)
          </p>
          <pre class="language-bash">
curl -o ctpkg https://cdngit.ruzhtw.top/pkghub/ctpkg
fish ctpkg grab upd
fish ctpkg grab ctpkg -y
rm ctpkg
      </pre
          >
        </details>
      </article>
    </div>
    <div class="col s1"></div>
  </div>
</template>