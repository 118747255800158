<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <h5>ConvallariaLinux</h5>
        <a
          href="https://github.com/TeaHouseLab/ConvallariaLinux"
          class="button round green"
          ><i>link</i><span>Available on Github</span></a
        >
        <button class="round green">Joshua@build1</button>
        <br /><br />
        <details class="card round">
          <summary>Features:</summary>
          <p>
            CtPKG and ctcontainer+darkwater, and TNi-panel built in<br />
            Secure and blazingly fast<br />
            Optimized for server usage<br />
            Code in fish shell script<br />
            Based on nspawn, easy to use ctcontainer front-end
          </p>
        </details>
        <details class="card round">
          <summary>Installation:</summary>
          Currently support for amd64, mips and arm are on their way<br />
          Just install it like a normal debian, the only difference is it has networkd and ctcontainer built in
          <br /><br />
          <a href="" class="button round red"><i>album</i>Convallaria-amd64-latest.iso</a>
        </details>
        <br />
      </article>
    </div>
    <div class="col s1"></div>
  </div><br /><br />
</template>
<script>
import Prism from "prismjs";
import "prismjs/components/prism-bash";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>