<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
        <div class="card round">
            <h5>
            Sorry :( <br />
            Nothing here, try to use the navbar at the bottom <br />
            This is the 404 Page
            </h5>
        </div>
    </div>
    <div class="col s1"></div>
  </div>
</template>
