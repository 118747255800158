<template>
  <div class="row">
    <div class="col s1"></div>
    <div class="col s10">
      <article class="round">
        <div class="row no-wrap">
          <div class="col min">
            <img class="circle extra" src="../assets/favicon.svg" />
          </div>
          <div class="col">
            <h5 class="no-margin">TeahouseLab</h5>
            <div>
              TeahouseLab, formerly known as the <strong>"BluelakeLab"</strong
              ><br />
              It was established in Feb 2017 by tsingkwai(happyeggchen on
              github)<br />
              It's a lab focus on linux tech and opensource software
            </div>
          </div>
        </div>
      </article>
      <article class="round">
        <div class="row no-wrap">
          <div class="col min">
            <img class="circle extra" src="../assets/pfp.webp" />
          </div>
          <div class="col">
            <h5 class="no-margin">Our mascot</h5>
            <div>
              She's a fox called "崗弧", was original created by zsr<br />Published
              in Creative Commons Attribution-NonCommercial 4.0 International
              Public License
            </div>
          </div>
        </div>
        <nav>
          <a class="button" href="../assets/mascot.png">Download Here</a>
        </nav>
      </article>
    </div>
    <div class="col s1"></div>
  </div>
</template>